import React from 'react';
import './bandeau.css';

const Bandeau = () => {
  return (
    <div className="bandeau-container">
      <div className="bandeau-content">
        <span className="bandeau-text">Recherches utilisateurs</span>
        <span className="bandeau-text">UX Design</span>
        <span className="bandeau-text">Figma Lover</span>
        <span className="bandeau-text">UI Design</span>
        <span className="bandeau-text">Facilitation</span>
        <span className="bandeau-text">Rennaise 100% pur beurre</span>
        <span className="bandeau-text">Product Design</span>
        <span className="bandeau-text">Design system</span>
        <span className="bandeau-text">Intégration</span>
        <span className="bandeau-text">Branding</span>
        <span className="bandeau-text">HTML/CSS/React/php</span>
        <span className="bandeau-text">Wordpress</span>
        <span className="bandeau-text">Discovery</span>
        <span className="bandeau-text">Delivery</span>
        <span className="bandeau-text">Product Marketing</span>
        <span className="bandeau-text">Massages ayurvédiques</span>
        <span className="bandeau-text">FrenchProduit</span>
        <span className="bandeau-text">Design sprint challenge</span>
        <span className="bandeau-text">Recherches utilisateurs</span>
        <span className="bandeau-text">UX Design</span>
        <span className="bandeau-text">Figma Lover</span>
        <span className="bandeau-text">UI Design</span>
        <span className="bandeau-text">Facilitation</span>
        <span className="bandeau-text">Rennaise 100% pur beurre</span>
        <span className="bandeau-text">Product Design</span>
        <span className="bandeau-text">Design system</span>
        <span className="bandeau-text">Intégration</span>
        <span className="bandeau-text">Branding</span>
        <span className="bandeau-text">HTML/CSS/React/php</span>
        <span className="bandeau-text">Wordpress</span>
        <span className="bandeau-text">Discovery</span>
        <span className="bandeau-text">Delivery</span>
        <span className="bandeau-text">Product Marketing</span>
        <span className="bandeau-text">Massages ayurvédiques</span>
        <span className="bandeau-text">FrenchProduit</span>
        <span className="bandeau-text">Design sprint challenge</span>
        <span className="bandeau-text">Recherches utilisateurs</span>
        <span className="bandeau-text">UX Design</span>
        <span className="bandeau-text">Figma Lover</span>
        <span className="bandeau-text">UI Design</span>
        <span className="bandeau-text">Facilitation</span>
        <span className="bandeau-text">Rennaise 100% pur beurre</span>
        <span className="bandeau-text">Product Design</span>
        <span className="bandeau-text">Design system</span>
        <span className="bandeau-text">Intégration</span>
        <span className="bandeau-text">Branding</span>
        <span className="bandeau-text">HTML/CSS/React/php</span>
        <span className="bandeau-text">Wordpress</span>
        <span className="bandeau-text">Discovery</span>
        <span className="bandeau-text">Delivery</span>
        <span className="bandeau-text">Product Marketing</span>
        <span className="bandeau-text">Massages ayurvédiques</span>
        <span className="bandeau-text">FrenchProduit</span>
        <span className="bandeau-text">Design sprint challenge</span>
        <span className="bandeau-text">Recherches utilisateurs</span>
        <span className="bandeau-text">UX Design</span>
        <span className="bandeau-text">Figma Lover</span>
        <span className="bandeau-text">UI Design</span>
        <span className="bandeau-text">Facilitation</span>
        <span className="bandeau-text">Rennaise 100% pur beurre</span>
        <span className="bandeau-text">Product Design</span>
        <span className="bandeau-text">Design system</span>
        <span className="bandeau-text">Intégration</span>
        <span className="bandeau-text">Branding</span>
        <span className="bandeau-text">HTML/CSS/React/php</span>
        <span className="bandeau-text">Wordpress</span>
        <span className="bandeau-text">Discovery</span>
        <span className="bandeau-text">Delivery</span>
        <span className="bandeau-text">Product Marketing</span>
        <span className="bandeau-text">Massages ayurvédiques</span>
        <span className="bandeau-text">FrenchProduit</span>
        <span className="bandeau-text">Design sprint challenge</span>

        {/* Ajoute plus de mots si nécessaire */}
      </div>
    </div>
  );
};

export default Bandeau;
